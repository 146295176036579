import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../templates/layout'
import PageHelmet from '../components/page-helmet'
import {unblockPortableText} from '../helpers/sanity'
import {localiseObject} from '../helpers/locale'
import {SITE_URL} from '../helpers/constants'

import '../scss/pages/index.scss'

export const query = graphql`

  query AncillaryPageQuery($id: String) {
    sanityAncillaryPage(id: { eq: $id }) {
      _rawBody
      slug {
        current
      }
      title {
        en
        fr
      }
      pageSeo {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
      }
    }
  }
`

function AncillaryPage ({data: {sanityAncillaryPage: {title, pageSeo, _rawBody}}}) {

  return (
    <Layout fixHeader={true} topPadding={70}>
      <PageHelmet
        url={`${SITE_URL}/${title.en}`}
        pageSeo={pageSeo}
      />
      <section className="ancillary-text">
        {unblockPortableText(localiseObject(_rawBody))}
      </section>
    </Layout>
  )
}

export default AncillaryPage
